/* eslint-disable no-undef */
declare const __APIUrl__: string;
declare const __ADDINVersion__: string;

let accessTokenPromise: Promise<string> | null = null;

const getAccessToken = (): Promise<string> => {
  if (accessTokenPromise) {
    return accessTokenPromise;
  }

  accessTokenPromise = OfficeRuntime.auth
    .getAccessToken()
    .then((token) => {
      accessTokenPromise = null;
      return token;
    })
    .catch((error) => {
      accessTokenPromise = null;
      throw error;
    });

  return accessTokenPromise;
};

const signallyNotificationId: string = "Signally.Notification";

const getTypeSignature = (composeType: string, isInternal: boolean): string => {
  console.log("Compose Type reçu dans getTypeSignature:", composeType);
  console.log("Is Internal:", isInternal);

  if (isInternal) return "internSignature";
  if (composeType === Office.MailboxEnums.ComposeType.Reply) return "replySignature";
  if (composeType === Office.MailboxEnums.ComposeType.Forward) return "forwardSignature";
  return "publicSignature";
};

const getComposeType = (): Promise<{ composeType: string; coercionType: string }> => {
  return new Promise((resolve, reject) => {
    if (Office.context.mailbox.item.getComposeTypeAsync) {
      Office.context.mailbox.item.getComposeTypeAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("Compose Type récupéré:", asyncResult.value);
          resolve(asyncResult.value);
        } else {
          reject(asyncResult.error);
        }
      });
    } else {
      // Si getComposeTypeAsync n'est pas supporté (par exemple sur mobile), utiliser "newMail"
      resolve({ composeType: "newMail", coercionType: "html" });
    }
  });
};

const getSignature = async (
  token: string,
  signatureType: string,
  userEmail: string,
  isAutorun: boolean
): Promise<string | null> => {
  let apiUrl = `${__APIUrl__}/connect/azure/check?code=${token}&email=${encodeURIComponent(userEmail)}&autorun=${isAutorun}`;
  console.log(apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Addin-Version": __ADDINVersion__,
      },
    });
    if (!response.ok) {
      return signallyAPICallErrors(response);
    }
    const json = await response.json();
    console.log("Signature récupérée pour le type:", signatureType);
    return json[signatureType];
  } catch (error) {
    console.error(error);
    sendNotification(error);
    return null;
  }
};

const getDomainFromEmail = (email: string): string => {
  return email.substring(email.lastIndexOf("@") + 1).toLowerCase();
};

const isInternalRecipient = async (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (Office.context.mailbox.item.from && Office.context.mailbox.item.from.getAsync) {
      Office.context.mailbox.item.from.getAsync((fromResult) => {
        if (fromResult.status === Office.AsyncResultStatus.Succeeded) {
          const senderEmail = fromResult.value.emailAddress;
          const senderDomain = getDomainFromEmail(senderEmail);

          Office.context.mailbox.item.to.getAsync((toResult) => {
            if (toResult.status === Office.AsyncResultStatus.Succeeded) {
              const recipients = toResult.value as Office.EmailAddressDetails[];

              if (recipients.length === 0) {
                console.log("Aucun destinataire présent.");
                resolve(false);
                return;
              }

              const isInternal = recipients.every(
                (recipient) => getDomainFromEmail(recipient.emailAddress) === senderDomain
              );
              resolve(isInternal);
            } else {
              reject(toResult.error);
            }
          });
        } else {
          reject(fromResult.error);
        }
      });
    } else {
      // Si les méthodes ne sont pas disponibles, supposer que ce n'est pas interne
      resolve(false);
    }
  });
};

const handleAction = async (event: Office.AddinCommands.Event, isAutorun: boolean) => {
  let userTokenEncoded;
  let composeTypeObj;
  let isInternal = false;
  let userEmail = "";

  try {
    userTokenEncoded = await getAccessToken();

    userEmail = Office.context.mailbox.userProfile.emailAddress;

    composeTypeObj = await getComposeType();
    console.log("Compose Type après récupération:", composeTypeObj.composeType);

    isInternal = await isInternalRecipient();
  } catch (error) {
    console.error(error);
    event.completed();
    return;
  }

  let signatureType = getTypeSignature(composeTypeObj.composeType, isInternal);
  console.log("Type de signature détecté:", signatureType);

  let signature = await getSignature(userTokenEncoded, signatureType, userEmail, isAutorun);

  if (signature && signature.trim().length > 10) {
    if (Office.context.mailbox.item.disableClientSignatureAsync) {
      Office.context.mailbox.item.disableClientSignatureAsync((asyncResult) => {
        if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
          console.error(asyncResult.error);
        }
      });
    }

    if (Office.context.mailbox.item.notificationMessages) {
      Office.context.mailbox.item.notificationMessages.removeAsync(signallyNotificationId);
    }

    Office.context.mailbox.item.body.setSignatureAsync(
      signature,
      {
        coercionType: "html",
        asyncContext: event,
      },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.error("Erreur lors de l'insertion de la signature :", asyncResult.error);
        }
        asyncResult.asyncContext.completed();
      }
    );
  } else {
    console.log("Aucune signature appliquée ou signature trop courte.");
    event.completed();
  }
};

// Fonction pour l'événement de composition d'un nouveau message
const onNewMessageComposeHandler = async (event: Office.AddinCommands.Event) => {
  console.log("Événement OnNewMessageCompose détecté.");
  await handleAction(event, true); // Passe true pour indiquer que c'est un autorun
};

// Fonction pour l'action utilisateur
const userAction = async (event: Office.AddinCommands.Event) => {
  console.log("Action utilisateur détectée.");
  await handleAction(event, false); // Passe false pour indiquer que c'est un clic utilisateur
};

const sendNotification = (error: any) => {
  let errorMessage = error.code
    ? error.message
    : error.message
      ? error.message
      : error.detail
        ? error.detail
        : error.toString();
  let message: Office.NotificationMessageDetails = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
    message: `${errorMessage.substring(0, 150)}`,
    icon: "Icon.80x80",
    persistent: true,
  };
  if (Office.context.mailbox.item.notificationMessages) {
    Office.context.mailbox.item.notificationMessages.replaceAsync(signallyNotificationId, message);
  }
};

const signallyAPICallErrors = (response: Response): Promise<never> => {
  return response.text().then((text) => {
    let errorMessage;
    if (response.status === 404 || response.status === 403) {
      errorMessage = JSON.stringify({ code: response.status, message: JSON.parse(text) });
    } else {
      errorMessage = text;
    }
    throw new Error(errorMessage);
  });
};

// Associer les fonctions d'événement
Office.actions.associate("onNewMessageComposeHandler", onNewMessageComposeHandler);
Office.actions.associate("userAction", userAction);
